@import "../../styles/media.scss";
@import "../../styles/var.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
}
.consultation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 290px;
  background: linear-gradient(0deg, #f7f4fa, #f7f4fa), #281a30;
  margin-bottom: $marginBottomSection;
}
.title {
  width: 100%;
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #281a30;
  @include _480 {
    display: flex;
    justify-content: center;
    padding-bottom: 12px
  }
}
.text {
  max-width: 480px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #281a30;
  @include _480 {
    text-align: justify;
  }
}
.btn {
  width: 100%;
  max-width: 280px;
  height: 70px;
  background: linear-gradient(86.04deg, #5f2596 -1.62%, #7130af 105.26%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.btn_link {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}
