@import-normalize;
@import "./media.scss";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  transition: 0.3s;
}

h3 {
  margin: 0 auto;
  text-align: center;
  font-family: Bebas Neue;
  font-style: normal;
  font-size: 72px;
  color: #281a30;
  @include _1220 {
    font-size: 64px;
  }
  @include _1060 {
    font-size: 56px;
  }
  @include _600 {
    font-size: 44px;
  }
}

body .popup-content {
  background: transparent;
  width: auto;
  padding: 0;
  border: none;
}

.popup-content .close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -20px;
  right: -20px;
  width: 46px;
  height: 46px;
  color: #281a30;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  transition: background 0.25s;
  box-sizing: border-box;
  z-index: 2;
  font-size: 30px;
  outline: none;
  &:hover {
    background: #eaeaea;
  }
  @include _600 {
    top: 24px;
    right: 24px;
  }
}
