@import "../../styles/media.scss";
@import "../../styles/var.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
  gap: 44px;
  position: relative;
}
.reviews {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  background: #000000;
  height: 674px;
  margin-bottom: 120px;
}
.reviews_main {
  display: flex;
  background: #000000;
  position: relative;
  height: 674px;
}

.reviews_bg_title {
  position: absolute;
  top: calc(50% - 100px);
  left: -60px;
  font-family: Bebas Neue Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 200px;
  color: rgba(255, 255, 255, 0.12);
  transform: rotate(-90deg);
  pointer-events: none;
  @include _990 {
    font-size: 140px;
  }
  @include _600 {
    top: 130px;
    transform: none;
    left: 0;
    font-size: 32vw;
  }
}

.slider_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 73%;
  margin-left: 27%;
  @include _990 {
    width: 80%;
    margin-left: 20%;
  }
  @include _600 {
    width: 100%;
    margin-left: 0;
  }
}

.slider_top {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  padding: 0 10px;
  @include _1060 {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 12px;
  }
}
.title {
  color: #ffffff;
  font-family: Bebas Neue Light;
  font-weight: 300;
  margin: 0;
}
.bold {
  font-family: Bebas Neue Bold;
  font-style: normal;
  font-weight: bold;
}
.slider_nav {
  display: flex;
  gap: 12px;
  font-size: 72px;
  @include _1060 {
    font-size: 56px;
  }
  @include _990 {
    font-size: 44px;
  }
}
.count {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Bebas Neue Light;
  font-style: normal;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
}
.current {
  width: 1em;
}
.total {
  width: 1em;
}
.arrow {
  height: 52px;
  width: 52px;
  @include _990 {
    height: 28px;
    width: 28px;
  }
}

.slide {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 94%;
  background: #fff;
  position: relative;
  padding: 20px 26px;
  margin-top: 60px;
  min-height: 282px;
  @include _990 {
    width: 98%;
  }
  @include _600 {
    width: 100%;
  }
  @include _480 {
    min-height: 308px;
  }
}
.slide_author {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-top: -60px;
}

.slide_img {
  display: flex;
  width: 110px;
  height: 110px;
}
.slide_name {
  display: flex;
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #20222a;
}

.slide_review {
  display: flex;
  width: 100%;
  text-align: justify;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #82879a;
}
.slide_auto {
  display: flex;
  width: 100%;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #20222a;
}
.btn_container {
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
  margin-top: 16px;
  @include _990 {
    padding-right: 12px;
  }
  @include _600 {
    padding-right: 0;
    justify-content: center;
  }
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 280px;
  height: 70px;
  background: linear-gradient(30.04deg, #f1841b 4.62%, #ef9759 105.26%);
  padding: 0 12px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.right,
.left {
  background: transparent;
  outline: none;
  border: none;
}
.auto {
  display: flex;
  position: absolute;
  left: clamp(-200px, -6vw, -20px);
  bottom: -20%;
  width: clamp(700px, 32vw, 828px);
  pointer-events: none;
  z-index: 1;
  @include _1060 {
    width: clamp(264px, 55vw, 828px);
  }
  @include _990 {
    bottom: -10%;
  }
  @include _600 {
    display: none;
  }
}
