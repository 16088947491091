@import "../../styles/media.scss";
@import "../../styles/var.scss";

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #000000;
  padding: 82px 92px 72px;
  gap: 12px;
  &.white_style {
    background: #ffffff;
    .title,
    .subtitle {
      color: #000000;
    }
    .formik_item {
      color: #82879a;
      &::placeholder {
        opacity: 1;
        color: #82879a;
      }
    }
    .invalid {
      color: #a92d2d;
      background: #fff;
    }
  }
  &.adaptive {
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 96vw;
    padding: 0;
    max-height: 740px;
    max-width: 540px;
    @include _480 {
      .formik_item {
        height: 40px;
      }
      .formik {
        gap: 10px;
      }
    }
  }
  @include _600 {
    padding: 56px 40px;
  }
}

.title {
  color: #fff;
  font-family: Bebas Neue Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  @include _1060 {
    font-size: 56px;
  }
  @include _600 {
    font-size: 44px;
  }
}

.subtitle {
  text-align: center;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  padding-bottom: 24px;
  color: #ffffff;
}

.formik {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.formik_item_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}
.invalid {
  position: absolute;
  bottom: -6px;
  font-size: 0.8em;
  padding: 0 8px;
  color: #ff0000;
  background: #ffffff;
}
.formik_item {
  width: 100%;
  // max-width: 320px;
  height: 70px;
  border: 2px solid #9E9E9E;
  text-align: center;
  background: none;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding: 0 16px;
  outline: none;
  &::placeholder {
    opacity: 1;
    color: #ffffff;
  }
}

.btn {
  width: 100%;
  text-align: center;
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  background: linear-gradient(86.04deg, #ff7917 -1.62%, #E25004 105.26%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  border: none;
  cursor: pointer;
  &:hover {
    background: linear-gradient(86.04deg, #f1841b -1.62%, #ef9759 105.26%);
  }
}

.textarea {
  resize: none;
  min-height: 130px;
  max-height: 200px;
  padding: 8px 16px;
}
