@import "../../styles/media.scss";
@import "../../styles/var.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
}
.advantages_leasing {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: $marginBottomSection;
}
.title_section {
  display: flex;
  justify-content: center;
  font-family: Bebas Neue Light;
  font-size: 40px;
  color: #281a30;
  text-align: center;
  padding-bottom: 8px;
}
.subtitle {
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #82879a;
}

.advantages_list {
  margin: 44px 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: stretch;
  flex-wrap: wrap;
}

.advantages_item {
  display: flex;
  width: 32%;
  flex: 1 1;
  min-width: 260px;
  padding: 16px 15px;
  border: 2px solid #f7f4fa;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 31px;
  color: #20222a;
  @include _600 {
    text-align: justify;
  }
}

.additional_advantages_list {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  align-items: stretch;
  flex-wrap: wrap;
}