@import "../../styles/media.scss";
@import "../../styles/var.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
}
.banner {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: $marginBottomSection
}
.title {
  font-family: Bebas Neue Light;
  font-weight: bold;
}
.link {
  color: #5E1C9D;
}
