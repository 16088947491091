@import "../../styles/media.scss";
@import "../../styles/var.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
}
.price {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-bottom: $marginBottomSection;
  @include _1220 {
    padding-top: 0;
  }
}
.title {
  display: flex;
  align-items: center;
  text-align: left;
  font-family: Bebas Neue Bold;
  font-weight: bold;
  margin: 0;
}
.line {
  display: flex;
  margin-bottom: 12px;
  width: 9vw;
  max-width: 160px;
  height: 5px;
  margin-left: 18px;
  background: #5e1c9d;
  @include _1220 {
    display: none;
  }
}
.subtitle {
  font-family: Sans;
  font-style: normal;
  //font-weight: normal;
  font-size: 18px;
  //color: #838383;
  color: #FF7917;
    font-weight: 700;
  padding-bottom: 15px;
}
.price__main {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  padding-bottom: 20px;
  @include _990 {
    gap: 36px;
    flex-direction: column;
    align-items: center;
  }
}
.sq_list_style {
  &::before {
    content: "";
    width: 8px;
    min-width: 8px;
    height: 8px;
    background: #000;
    margin-right: 9px;
  }
}
.price_left {
  width: 50%;
  @include _990 {
    width: 100%;
  }
  .description {
    text-align: justify;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #20222a;
    padding-bottom: 20px;
  }
  .cost_car_title {
    display: inline-flex;
    font-family: PT Sans;
    font-weight: bold;
    font-size: 20px;
    padding: 20px 0 15px;
    line-height: 26px;
  }
  .cost_car_list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 15px;
    .cost_car_item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: PT Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #20222a;
    }
  }
}
.price_right {
  display: flex;
  flex-direction: column;
  width: 50%;
 // min-width: 520px;
  padding: 0 0 16px 40px;
  @include _990 {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    column-gap: 12px;
    min-width: auto;
    padding-bottom: 36px;
  }
  .description {
    text-align: justify;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #20222a;
    padding-bottom: 20px;
  }
  .cost_car_title {
    display: inline-flex;
    font-family: PT Sans;
    font-weight: bold;
    font-size: 20px;
    padding: 20px 0 15px;
    line-height: 26px;
  }
  .cost_car_list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 15px;
    .cost_car_item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: PT Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #20222a;
    }
  }
  .price_package {
    border: solid 1px #5d1c9d;
    border-radius: 8px;
    padding: 12px;
    @include _990 {
      width: 48%;
      min-width: 360px;
      flex: 1;
    }
    @include _480 {
      width: 100%;
      min-width: auto;
      flex: none;
    }
  }
  .package_title {
    display: inline-flex;
    font-family: Bebas Neue Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    text-align: left;
    color: #5e1c9d;
    padding-bottom: 16px;
    @include _990 {
      justify-content: center;
      font-size: 36px;
      text-align: center;
      width: 100%;
    }
    @include _480 {
      font-size: 28px;
    }
  }
  .package_list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .package_list_item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: PT Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #20222a;
      padding-left: 22px;
    }
  }
}
.buttons {
  display: flex;
  gap: 12px;
  padding-bottom: 20px;
  @include _480 {
    display: none;
  }
  .btn {
    display: inline-flex;
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #281a30;
    border: 2px solid #f7f4fa;
    transition: 0.3s;
    background: #fff;
    padding: 28px 40px;
    @include _600 {
      padding: 28px 20px;
    }
    &:hover {
      border-color: #7130af;
      background: linear-gradient(86.53deg, #5f2596 -1.62%, #7130af 105.26%);
      color: #fff;
    }
  }
}
.footnotes_list {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid #EAEAEA;
  padding-top: 20px;
  .footnotes_item {
    align-items: center;
    font-family: Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: 0000000;
  }
}
.auto_container {
  display: flex;
  position: relative;
  width: 100;
  height: 50%;
  pointer-events: none;
  @include _990 {
    display: none;
  }
  .auto {
    display: flex;
    width: 44vw;
    max-width: 640px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: scale(-1, 1) translateY(-50%);
    pointer-events: none;
  }
}
