@import "../../styles/media.scss";
@import "../../styles/var.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
}
.ourWorks {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: $marginBottomSection;
}

.title {
  text-align: center;
  font-family: Bebas Neue Light;
  display: block;
  margin-bottom: 65px;
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  color: #000000;
  position: relative;
  @include _1220 {
    font-size: 64px;
  }
  @include _1060 {
    font-size: 56px;
  }
  @include _600 {
    font-size: 44px;
  }
}

.bold {
  font-family: Bebas Neue Bold;
}

.cards_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 26px;
  @include _990 {
    grid-template-columns: repeat(2, 1fr);
  }
  @include _600 {
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 400px;
  }
}

.card_text {
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #20222a;
  transition: 0.3s;
}
.card_description {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 2px solid #f7f4fa;
  border-top: none;
  transition: 0.3s;
}
.card_name {
  font-weight: bold;
  height: 95px;
}
.option_item {
  text-align: center;
}
.card_price {
  font-weight: normal;
}
.card_link {
  display: flex;
  flex-direction: column;
  border: 10px solid transparent;
  transition: 0.3s;
  &:hover {
    border: 10px solid #f1841b;
    background: #f1841b;
  }
  &:hover .card_description {
    border-color: #f1841b;
  }
  &:hover .card_text {
    color: #fff;
  }
}
.card_img {
  width: 100%;
}

.slider_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 73%;
    margin-left: 27%;
    @include _990 {
      width: 80%;
      margin-left: 20%;
    }
    @include _600 {
      width: 100%;
      margin-left: 0;
    }
  }
  
  .slider_top {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    padding: 0 10px;
    @include _1060 {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 12px;
    }
  }

  .slider_nav {
    display: flex;
    gap: 12px;
    font-size: 72px;
    @include _1060 {
      font-size: 56px;
    }
    @include _990 {
      font-size: 44px;
    }
  }
  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Bebas Neue Light;
    font-style: normal;
    font-weight: 300;
    text-align: center;
    color: #ffffff;
  }
  .current {
    width: 1em;
  }
  .total {
    width: 1em;
  }
  .arrow {
    height: 52px;
    width: 52px;
    @include _990 {
      height: 28px;
      width: 28px;
    }
  }
  
  .slide {
    display: flex;
    gap: 12px;
    flex-direction: column;
    width: 94%;
    background: #fff;
    position: relative;
    padding: 20px 26px;
    margin-top: 60px;
    min-height: 282px;
    @include _990 {
      width: 98%;
    }
    @include _600 {
      width: 100%;
    }
    @include _480 {
      min-height: 308px;
    }
  }
  .slide_author {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    margin-top: -60px;
  }
  
  .slide_img {
    display: flex;
    width: 110px;
    height: 110px;
  }
  .slide_name {
    display: flex;
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #20222a;
  }
  
  .slide_review {
    display: flex;
    width: 100%;
    text-align: justify;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #82879a;
  }
  .slide_auto {
    display: flex;
    width: 100%;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #20222a;
  }
  .btn_container {
    display: flex;
    justify-content: flex-end;
    padding-right: 24px;
    margin-top: 16px;
    @include _990 {
      padding-right: 12px;
    }
    @include _600 {
      padding-right: 0;
      justify-content: center;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 280px;
    height: 70px;
    background: linear-gradient(86.04deg, #5f2596 -1.62%, #7130af 105.26%);
    padding: 0 12px;
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  .right,
  .left {
    background: transparent;
    outline: none;
    border: none;
  }
  .auto {
    display: flex;
    position: absolute;
    left: clamp(-200px, -6vw, -20px);
    bottom: -20%;
    width: clamp(700px, 32vw, 828px);
    pointer-events: none;
    z-index: 1;
    @include _1060 {
      width: clamp(264px, 55vw, 828px);
    }
    @include _990 {
      bottom: -10%;
    }
    @include _600 {
      display: none;
    }
  }

  .slick-prev,
.slick-next {
  display: none !important;
}
.customPrevArrow,
.customNextArrow {
  position: relative;
  z-index: 99999;
}
.customPrevArrow {
  left: -3%;
}
.customNextArrow {
  right: -3%;
}
.customPrevArrow:hover,
.customNextArrow:hover {
  fill: #f1841b;
  cursor: pointer;
}

.slickSlider {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}