@mixin _1800 {
  @media (max-width: 1800px){
      @content;
  }
}
@mixin _1440 {
  @media (max-width: 1440px){
      @content;
  }
}
@mixin _1220 {
  @media (max-width: 1220px){
      @content;
  }
}
@mixin _1060 {
  @media (max-width: 1060px){
      @content;
  }
}
@mixin _990 {
  @media (max-width: 990px){
      @content;
  }
}
@mixin _600 {
  @media (max-width: 600px){
      @content;
  }
}
@mixin _480 {
  @media (max-width: 480px){
      @content;
  }
}
@mixin _420 {
  @media (max-width: 420px){
      @content;
  }
}
