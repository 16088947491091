@import "../../styles/media.scss";
@import "../../styles/var.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
}
.questions{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: $marginBottomSection;
}
.title {
  font-family: Bebas Neue Light;
  color: #281a30;
  text-align: center;
  padding-bottom: 8px;
}
.bold {
  font-family: Bebas Neue Bold;
  font-style: normal;
  font-weight: bold;
}
.subtitle {
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  padding-bottom: 46px;
  color: #82879a;
}
.accordion {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"]:checked + label .accordion_content {
  display: flex;
  align-items: center;
  visibility: visible;
  overflow: hidden;
  height: 128px;
  opacity: 1;
  width: 100%;
  transition: 0.8s;
  @include _480 {
    height: 158px;
  }
  @include _1220 {
    height: 158px;
  }

}
.accordion_content {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
  color: #82879A;
  visibility: hidden;
  height: 0px;
  opacity: 0;
  transition: 0.8s;
}
input[type="checkbox"]:checked + label {
  border: 2px solid #5E1C9D;
  transition: 0.8s;
}
label {
  display: flex;
  flex-direction: column;
  padding: 16px 30px;
  width: 100%;
  min-height: 110px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 31px;
  color: #20222a;
  border: 2px solid #F7F4FA;
  transition: 0.8s;
  cursor: pointer;
}
.quest {
  min-width: 310px;
  flex: 1;
  @include _600 {
    width: 100%;
  }
  @include _420 {
    min-width: 280px;
  }
}
