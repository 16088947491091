@import "../../styles/media.scss";

.container {
  display: flex;
  gap: 70px;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
  @include _1440 {
  gap: 10px;
  }
  @include _1060 {
    flex-direction: column;
    align-items: center;
    gap: 46px;
    padding-top: 22px;
  }
}
.main_block {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-top: 140px;
  padding-bottom: 140px;
  margin-bottom: clamp(40px, 19vw, 220px);
  background: radial-gradient(385px at 50% 50%, #000000 0%, #1E1E1E 100%);
  @include _1440 {
    padding-top: 90px;
    padding-bottom: 90px
  }
  @include _1060 {
    padding-bottom: 190px;
  }
  &::after {
    content: '';
    position: absolute;
    left: 35%;
    right: 30px;
    margin: 0 auto;
    bottom: -280px;
    max-width: 1200px;
    min-width: 730px;
    width: 64vw;
    height: 640px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(./img/auto2.png);
    @include _1220 {
      width: 25vw;
      min-width: 550px;
      height: 300px;
      bottom: -67px;
    }
    @include _1060 {
      width: 61vw;
      right: 0;
      left: 0;
      min-width: 310px;
      bottom: -94px;
    }
  }
}

.scroll_cont {
    position: absolute;
    transform: rotate(-90deg);
    top: 218px;
    left: clamp(-340px,-10vw ,-144px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include _1440 {
      display: none;
    }
    i {
      float: left;
      width: 110px;
      height: 2px;
      background: #fff;
      margin-right: 26px;
    }
    img {
      float: left;
      margin-right: 22px;
    }
    span {
      float: left;
      font-family: PT Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }

.main_block__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  max-width: 1110px;
  gap: 42px;
  @include _1440 {
    max-width: 540px;
  }
  @include _1220 {
    max-width: 500px;
  }
  @include _1060 {
    max-width: 70%;
    align-items: center;
  }
  @include _480 {
    max-width: 90%;
    align-items: center;
  }
  .exleasingcar {
    width: 180px;
    display: inline-block;
  }
  h1 {
    width: 100%;
    font-family: Bebas Neue Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 75px;
    color: #ffffff;
    span {
      position: relative;
      color: #8D3ED8;
      &::before {
        content: "2";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-family: Bebas Neue Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 2em;
        color: rgba(255, 255, 255, 0.05);
        @include _1060 {
          display: none;
        }
      }
    }

    @include _1440 {
      font-size: 56px;
      line-height: 65px;
      height: 180px;
    }
    @include _1220 {
      font-size: 44px;
      line-height: 55px;
      height: 152px;
    }
    @include _1060 {
      height: auto;
      text-align: justify;
    }
  }
  .consult {
    width: 100%;
    max-width: 280px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(86.04deg, #ff7917 -1.62%, #f1841b 105.26%);
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    &:hover {
      background: linear-gradient(30.04deg, #f1841b 4.62%, #ef9759 105.26%);
    }
  }
  .know_more__cont {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 280px;
    .know_more {
      font-family: PT Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      position: relative;
      &:hover:before {
        width: 0;
      }
      span {
        position: relative;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 6px;
        background: #5e1c9d;
        bottom: 2px;
        transition: 0.3s;
      }
    }
  }
}

.main_block__right {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 570px;
  position: relative;
  @include _1440 {
    max-width: 560px;
  }
  @include _1220 {
    max-width: 540px;
  }
  @include _1060 {
    max-width: 600px;
  }
  h2 {
    width: 100%;
    font-family: Bebas Neue Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 65px;
    color: #ffffff;
    @include _1060 {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  .title {
    position: absolute;
    top: 0;
    left: 34px;
    font-family: Bebas Neue Bold;
    font-style: normal;
    font-weight: bold;
    font-size: clamp(120px, 10vw, 198px);
    color: rgba(255, 255, 255, 0.05);
     @include _1060 {
      display: flex;
      justify-content: center;
      width: 100%;
      top: 10px;
      left: auto;
      right: auto;
      font-size: 90px;
     }
  }
  .scroll__ul {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-left: 20px;
    li {
      display: flex;
      position: relative;
      justify-content: flex-start;
      align-items: center;
      height: 56.5px;
      background-size: 70px 56.5px;
      background-repeat: no-repeat;
      background-position: left;
      background-image: linear-gradient(
        88.77deg,
        #5f2596 -1.62%,
        #7130af 105.26%
      );
    }
    img {
      padding-left: 4px;
      padding-right: 8px;
    }
    a {
      font-family: PT Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 25px;
      color: #ffffff;
      transform: translateX(0px);
      transition: 0.3s;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.5px;
    }
    a:hover {
      transform: translateX(8px);
    }
  }
}

.title_p {
  display: flex;
  width: 100%;
  max-width: 1000px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  @include _1060 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
