@import "../../styles/media.scss";
@import "../../styles/var.scss";

.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
}
.footer_top {
  width: 100%;
  display: flex;
  padding: 34px 0;
  border-bottom: 2px solid #f7f4fa;
  @include _600 {
    padding: 18px 0;
  }
  .container {
    @include _990 {
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }
  }
}
.logo_container {
  display: flex;
  gap: 24px;
  align-items: center;

  .logo {
    position: relative;
    font-family: Bebas Neue Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #ff7917;
  }
  .subtitle {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;
  }
}
.nav {
  display: flex;
  align-items: center;
  gap: 12px;
  @include _990 {
    flex-wrap: wrap;
    justify-content: center;
  }
  @include _600 {
    flex-direction: column;
  }
  .nav_item {
  }
  .nav_link {
    font-family: Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #000000;
    &:hover {
      color: #838383;
    }
  }
}
.footer_bottom {
  .container {
    flex-direction: column;
  }
}
.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
  @include _600 {
    gap: 12px;
    flex-direction: column;
  }
//   .developer {
//     font-family: PT Sans;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 18px;
//     color: #82879a;
//   }
//   .link_dev {
//     color: #82879a;
//     text-decoration: none;
//     &:hover {
//       color: #000;
//       text-decoration: underline;
//     }
//   }
  .copyright_text {
    font-family: Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #838383;
  }
}
// .description {
//   display: flex;
//   justify-content: space-between;
//   font-size: 12px;
//   line-height: 12px;
//   gap: 12px;
//   @include _600 {
//     flex-direction: column;
//   }
// }
// .description_item {
//   display: inline;
// }
// .left_descr {
//   .title_models {
//     display: flex;
//   }
//   .models_list {
//     display: inline;
//   }

//   .title_leasing {
//     display: flex;
//   }
//   .leasing_list {
//     display: inline;
//   }
// }
// .right_descr {
//   .title_options {
//     display: flex;
//   }
//   .options_list {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     white-space: break-spaces;
//     min-width: 300px;
//     @include _600 {
//       min-width: auto;
//     }
//   }
// }
