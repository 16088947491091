@import "../../styles/media.scss";
$min-height-header: 72px;
.header {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  min-height: $min-height-header;
  padding-top: 20px;
  z-index: 1;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
}
.logo {
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  height: 28px;
  color: #ffffff;
  text-shadow: 2px 1px 2px #ff7917, 0 0 1em #ff7917;
  @include _600 {
    font-size: 28px;
    height: 22px;
  }
}
.right_header {
  display: flex;
  gap: 20px;
}
.contacts {
  display: flex;
  align-items: center;
  gap: 20px;
  @include _990 {
    gap: 2vw;
  }
  @include _420 {
    display: none;
  }
  .contacts__item {
    display: flex;
    align-items: center;
  }
  .socials__link {
    display: flex;
  }
  
}
.contacts__link {
    color: white;
    font-family: sans-serif;
    font-size: 15px;
    &:hover {
       text-decoration: underline;
      }
}

.menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    @include _990 {
      gap: 7px;
    }
    @include _600 {
      display: none;
    }
    
  }

.contacts__socials {
  display: flex;
  gap: 20px;
  @include _990 {
    gap: 2vw;
  }
  @include _600 {
    display: none;
  }
}
.number {
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: right;
  white-space: nowrap;
  color: #ffffff;
  @include _600 {
    font-size: 15px;
  }
}
.hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}
.menu_mob_but {
  width: 100%;
  float: left;
  height: auto;
  max-width: 40px;
  background: none;
  padding: 0;
  border: none;
}
.menu_mob_but span {
  width: 100%;
  float: left;
  height: 2px;
  border-radius: 5px;
  margin-top: 7px;
  background: #ffffff;
}
.menu_mob_but span:first-child {
  margin-top: 0;
}

.select {
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px;
}

.option_custom {
  background: white;
}

