@import "../../styles/media.scss";
@import "../../styles/var.scss";

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr auto;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
  gap: 44px;
  @include _1220 {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    gap: 0;
  }
}
// .contacts {
//   display: flex;
//   justify-content: center;
//   position: relative;
//   width: 100%;
//   overflow: hidden;
//   &::before {
//     content: "";
//     width: 40%;
//     height: 80%;
//     position: absolute;
//     right: 0;
//     top: 10%;
//     background: radial-gradient(385px at 50% 50%, #000000 0%, #1E1E1E 100%);
//     z-index: -1;
//     @include _1220 {
//       width: 100%;
//       height: 60%;
//       top: 26%;
//     }
//     @include _600 {
//       display: none;
//     }
//   }
// }
.left_block {
  display: flex;
  flex-direction: column;
  gap: 18px;
  @include _1220 {
    align-items: center;
    margin-bottom: 26px;
  }
}
.title {
  font-family: Bebas Neue Bold;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  color: #000000;
  margin: 0;
  white-space: nowrap;
  @include _600 {
    text-align: center;
    white-space: normal;
  }
}
.bold {
  color: #5e1c9d;
  display: contents;
}
.line {
  display: inline-flex;
  max-width: 76px;
  width: 100%;
  margin-bottom: 0.3em;
  margin-left: 12px;
  height: 5px;
  background: #5e1c9d;
  @include _600 {
    display: none;
  }
}
.subtitle {
  width: 100%;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 31px;
  color: #82879a;
  @include _1220 {
    text-align: center;
    padding: 0 20px;
  }
}
.address {
  display: flex;
  flex-direction: column;
  gap: 12px;
  @include _1220 {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
  @include _600 {
    flex-direction: column;
    align-items: center;
  }
}
.address_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
}
.address_item {
  width: 100%;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #000000;
}
.contacts_block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contacts_title {
  font-size: 22px;
  font-weight: bold;
}
.contacts_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contacts_item {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #000000;
}
.social {
  display: flex;
  gap: 4px;
}
.social_icon {
  display: flex;
  width: 22px;
  height: 22px;
}
.form_size {
  max-width: 570px;
  @include _1220 {
    max-width: 800px;
  }
}
.right_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  grid-area: 1 / 2 / 3 / 3;
  @include _1220 {
    grid-area: auto;
  }
}
.bg_title {
  font-weight: bold;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  position: absolute;
  display: flex;
  font-family: Bebas Neue Bold;
  font-size: 144px;
  right: clamp(-160px, -5vw, 51px);
  height: 100%;
  color: #fff;
  z-index: -1;
  justify-content: center;
  @include _1220 {
    right: clamp(-160px, 2vw, 51px);
  }
  @include _600 {
    display: none;
  }
}
.map {
  min-height: 380px;
  height: 100%;
  @include _1220 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
