@import "../../styles/media.scss";
@import "../../styles/var.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
  @include _1440 {
    gap: 10px;
  }
  @include _1060 {
    flex-direction: column;
    align-items: center;
    gap: 46px;
    padding-top: 22px;
  }
}
.services {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: $marginBottomSection;
}
.services__main {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  @include _1060 {
    flex-direction: column;
    gap: 44px;
  }
  .services__left {
    width: 100%;
    max-width: 570px;
    @include _1060 {
      text-align: justify;
    }
  }
  .services__right {
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    gap: 44px;
  }
}
.description {
  padding-top: 46px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #20222a;
  @include _1060 {
    text-align: justify;
    width: clamp(570px, 86%, 900px);
    padding: 0;
  }
  @include _600 {
    width: 100%;
  }
  .reg_p {
    width: 100%;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #20222a;
    padding-bottom: 12px;
  }
}
.services__left {
  h3 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: Bebas Neue Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    color: #281a30;
    position: relative;
    white-space: nowrap;
    margin-bottom: 12px;
    @include _1220 {
      font-size: 64px;
    }
    @include _1060 {
      font-size: 56px;
    }
    @include _600 {
      font-size: 44px;
    }
  }
  h3 span {
    color: #5e1c9d;
  }
  h3 i {
    width: 100%;
    max-width: 140px;
    height: 5.74px;
    margin-left: 30px;
    background: #5e1c9d;
  }
  .title_p {
    width: 100%;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #82879a;
    margin-bottom: 40px;
  }
  .reg_p {
    width: 100%;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #20222a;
    margin-bottom: 25px;
    padding-bottom: 12px;
  }
  .reg_p span {
    font-weight: 700;
  }
  .reg_p:last-child {
    margin-bottom: 0;
  }
}

.services__right ul {
  width: 100%;
  max-width: 570px;
}
@mixin animations($delay) {
  animation-name: wave;
  animation-duration: 2s;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
.services__right ul {
  li {
    float: left;
    width: 100%;
    max-width: 523px;
    transition: 0.3s;
    margin-bottom: 10px;
    border: 2px solid #f7f4fa;
    position: relative;
  }
  li:first-child {
    @include animations(1s);
  }
  li:nth-child(2) {
    @include animations(2.5s);
  }
  li:nth-child(3) {
    @include animations(3.5s);
  }
  li:last-child {
    @include animations(4s);
  }
  li div {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    cursor: default;
  }
  li div .li_left {
    width: 80%;
    padding-left: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  li div .li_left p {
    margin-left: 15px;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #20222a;
  }
  li div .li_left figure {
    width: 65px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  li div .li_right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    right: -24px;
    font-family: Bebas Neue Bold;
    font-style: normal;
    font-size: 64px;
    text-align: center;
    color: #f7f4fa;
    transition: 0.1s;
  }
}
.expert_consult__cont {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include _600 {
    justify-content: center;
  }
}
.expert_consult__cont a {
  width: 100%;
  max-width: 280px;
  height: 70px;
  background: linear-gradient(86.04deg, #5f2596 -1.62%, #7130af 105.26%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.expert_consult__cont a:hover + p {
  transform: none;
}
.expert_consult__cont p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #5e1c9d;
  transform: translateX(-18px);
  transition: 0.3s;
}
.expert_consult__cont p i {
  width: 110px;
  height: 2px;
  background: #f7f4fa;
  margin-right: 7px;
}
@keyframes wave {
  0% {
    border: 2px solid #5e1c9d;
    max-width: 523px;
  }
  50% {
    border: 2px solid #5e1c9d;
    max-width: 570px;
  }
  100% {
    border: 2px solid #5e1c9d;
    max-width: 523px;
  }
}
