@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');
@font-face {
	font-family: 'Bebas Neue Thin';
	src: url('../fonts/hinted-BebasNeue-Thin.woff') format('woff'),
		url('../fonts/hinted-BebasNeue-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue Bold';
	src: url('../fonts/hinted-BebasNeueBold.woff') format('woff'),
		url('../fonts/hinted-BebasNeueBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('../fonts/hinted-BebasNeueRegular.woff') format('woff'),
		url('../fonts/hinted-BebasNeueRegular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue Book';
	src: url('../fonts/hinted-BebasNeueBook.woff') format('woff'),
		url('../fonts/hinted-BebasNeueBook.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue Light';
	src: url('../fonts/hinted-BebasNeueLight.woff') format('woff'),
		url('../fonts/hinted-BebasNeueLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}