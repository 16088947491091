@import "../../styles/media.scss";
@import "../../styles/var.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
}
.advantages {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-top: clamp(0px, 4vw,80px);
  margin-bottom: $marginBottomSection;
  @include _1220 {
    padding-top: 0;
  }
}


.advantages__main {
  display: flex;
}
.advantages__us_left {
  width: clamp(440px, 38vw, 575px);
  position: relative;
  z-index: 2;
  margin-top: clamp(-312px, -9vw, -12px);
  align-items: center;
  @include _1060 {
    width: clamp(340px, 38vw, 472px);
  }
  @include _990 {
    display: none;
  }
  img {
    width: 100%;
  }
}
.advantages__us_right {
  width: clamp(400px, 50%, 700px);
  position: relative;
  z-index: 1;
  @include _990 {
    width: 100%;
  }
}
.advantages h3 {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  font-family: Bebas Neue Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  color: #281a30;
  margin-bottom: 12px;
  position: relative;
  gap: 16px;
  @include _1220 {
    font-size: 64px;
  }
  @include _1060 {
    font-size: 56px;
  }
  @include _990 {
    justify-content: center;
  }
  @include _600 {
    flex-direction: column;
    font-size: 44px;
    padding-top: 0;
  }
}
.advantages h3 span {
  color: #ff7917;
  margin-left: 3px;
}
.advantages h3 i {
  width: 140px;
  height: 5.74px;
  background: #ff7917;
}
.advantages .title_p {
  display: flex;
  justify-content: right;
  width: 100%;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #82879a;
  margin-bottom: 60px;
  @include _990 {
    justify-content: center;
  }
  @include _600 {
    text-align: justify;
  }
}
.advantages__us_right .advantages_us__cont {
  display: flex;
  width: 100%;
  padding: 85px 120px 90px 0;
  position: relative;
  background: #EAEAEA;
  min-width: 585px;
  @include _1060 {
    padding: 60px 120px 40px 0;
    min-width: 515px;
  }
  @include _990 {
    padding: 34px 120px 34px 20px;
    min-width: auto;
  }
  @include _600 {
    padding-right: 20px;
  }
  &::before {
    content: "";
    position: absolute;
    left: -40%;
    top: 0;
    width: 40%;
    height: 100%;
    background: #EAEAEA;
    @include _990 {
      display: none;
    }
  }
}
.advantages__us_right .advantages_us__cont .rotate_span {
  position: absolute;
  transform: rotate(90deg);
  font-family: Bebas Neue Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 100px;
  text-align: center;
  color: #ffffff;
  top: calc(50% - 50px);
  right: -190px;
  @include _990 {
    font-size: 76px;
    top: calc(50% - 38px);
    right: -126px;
  }
  @include _600 {
    display: none;
  }
}
.advantages__us_right .advantages_us__cont ul {
  display: flex;
  flex-direction: column;
  gap: clamp(20px, 4vw, 76px);
  width: 100%;
  @include _1060 {
    gap: 20px;
  }
  @include _990 {
    gap: 36px;
  }
}
.advantages__us_right .advantages_us__cont ul li {
  width: 100%;
}
.advantages__us_right .advantages_us__cont ul li:last-child {
  margin-bottom: 0;
}
.advantages__us_right .advantages_us__cont ul li figure {
  float: left;
  width: 68px;
  height: 68px;
  position: relative;
  background: #ff7917;
  @include _600 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
  }
}
.advantages__us_right .advantages_us__cont ul li figure img {
  position: absolute;
  bottom: 8px;
  left: 10px;
  width: 50px;
  @include _600 {
    position: static;
    width: 60px;
  }
}
.advantages__us_right .advantages_us__cont ul li p {
  float: left;
  width: calc(100% - 70px);
  padding-left: 65px;
  font-family: PT Sans;
  font-style: normal;
  font-size: 20px;
  line-height: 31px;
  color: #20222a;
  @include _600 {
    padding-left: 30px;
    line-height: 26px;
    text-align: justify;
  }
}
.advantages__us_right .advantages_us__cont ul li p span {
  font-family: Bebas Neue Bold;
  width: 100%;
  float: left;
}
