@import "../../../styles/media.scss";
.nav_overlay {
  position: fixed;
  background: rgb(24, 24, 28, 0.95);
  z-index: 2;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition-delay: 0s;
  transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);
}
.nav_overlay_show {
  position: fixed;
  background: rgb(24, 24, 28, 0.95);
  z-index: 2;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);
  top: 0;
  transition-delay: 0s;
}
body:has(.nav_overlay_show) {
  touch-action: none;
}
.hamburger {
    display: none;
    @include _600 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        z-index: 3;
    }
 
}
.navigation {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  height: 100%;
}
.nav_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.nav_link {
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: #FFFFFF;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 2px;
    background: #5F2596;
    transition: .3s;
  }
  &:hover::before {
    width: 100%;
    right: 0;
  }
}
.contacts {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}
  .contacts_item {
    display: flex;
    justify-content: center;
  }
  .contacts_link {
    text-align: center;
    font-size: 20px;
    font-family: Bebas Neue Bold;
    font-style: normal;
    color: #ffffff;
    opacity: 0.7;
    &:hover {
    opacity: 1;
    }
  }
}