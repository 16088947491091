@import "../../styles/media.scss";
@import "../../styles/var.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
  gap: 44px
}
.auctions {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-bottom: $marginBottomSection;
}
.represent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 14px;
  white-space: nowrap;
  width: 100%;
  font-size: 60px;
  font-family: Bebas Neue Light;
  text-align: center;
  color: #281A30;
  @include _1220 {
    font-size: 52px;
  }
  @include _1060 {
    font-size: 44px;
  }
  @include _600 {
    font-size: 32px;
    white-space: normal;
  }
}
.exleasingcar {
  width: 30%;
  min-width: 200px;
}
.title {
  font-family: Bebas Neue Light;
}
.bold {
  font-family: Bebas Neue Bold;
  font-style: normal;
  font-weight: bold;
}
.logotypes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px
}

.orangeBold {
    color: #ff7917;
    margin-left: 10px;
    font-family: Bebas Neue Bold;
  font-style: normal;
  font-weight: bold;
  }